.rounded-corners {
  border-radius: 50%;
}

.contact-info {
  overflow: hidden;
  list-style: none;
  padding-top: 16px;
}

.contact-info li {
  display: flex;
  border: none;
  line-height: 16px;
}

.contact-info li .info-title {
  font-size: 14px;
  color: #283e43;
  padding-left: 16px;
  font-weight: 600;
  /* float: left; */
  text-transform: capitalize;
  flex: 0 0 100px;
}

.contact-icon {
  font-size: 12px;
}
.contact-info li .info-title span {
  display: inline-block;
  vertical-align: middle;
}

.contact-info li .info-title > span {
  margin-right: 5px;
}

.contact-info li .info-title span {
  display: inline-block;
  vertical-align: middle;
}

.contact-info li .info-title > span {
  margin-right: 5px;
}
.info-detail {
  display: block;
  padding: 0 0 10px;
  flex: 1 1;
  position: relative;
}

.info-detail:after {
  content: ":";
  position: absolute;
  left: -11px;
  top: 0;
  display: block;
  font-weight: bold;
}
.contact-card {
  margin-top: 16px;
}
.margin-top-1 {
  margin-top: 40px;
}
.margin-bottom-1 {
  margin-top: 24px;
}

/*==========================
	07. resume Area
	==========================*/

.resume {
  background-color: #fff;
}

.resume-content {
  padding: 0 0 0 40px;
  position: relative;
  margin: 0 0 30px;
}

.resume-content:before,
.resume-content:after {
  content: "";
  position: absolute;
  display: block;
  left: 0;
  width: 2px;
  top: 16px;
}

.resume-content.animated:before {
  animation: drowLeft 300ms linear forwards;
  width: 30px;
  height: 2px;
  transform-origin: 100% 50%;
}

.resume-content.animated:after {
  width: 2px;
  animation: drowDown 1s cubic-bezier(0.32, 0.2, 0.55, 1) 250ms forwards;
}

.resume-content.animated .last-dots {
  position: absolute;
  left: -2px;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  bottom: 0;
  display: block;
  transform: scale(0);
  animation: dotsBlock 400ms cubic-bezier(0.32, 2.2, 0.55, 1.6) 1.2s forwards;
}

@keyframes drowLeft {
  from {
    transform: scaleX(0);
  }

  to {
    transform: scaleX(1);
  }
}

@keyframes drowDown {
  from {
    bottom: 100%;
  }

  to {
    bottom: 0%;
  }
}

@keyframes dotsBlock {
  from {
    transform: scale(0);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

.resume-content h3 {
  font-weight: 500;
  margin: 0 0 20px;
}

.single-resume {
  overflow: hidden;
  padding: 20px 0 10px;
  border-bottom: 1px solid #eee;
}

.single-resume:nth-child(3) {
  border-top: 1px solid #eee;
}

.single-resume:last-child {
  padding: 20px 0;
}

.single-resume:last-child,
.single-resume:last-child p {
  margin: 0;
}

.resume-title {
  display: block;
  font-weight: bold;
  font-size: 14px;
  margin: 0 0 3px;
}

.date {
  display: block;
  font-size: 11px;
  margin: 0 0 3px;
  color: #888;
  font-weight: 500;
}

.resume-content .discribe {
  font-size: 14px;
  line-height: 20px;
}

/*==========================
	08. featured work area
	==========================*/

.featured-work {
  background-color: #fafafa;
}

.portfolio-filter {
  display: block;
}

.filter-btn {
  background: transparent;
  padding: 0 12px;
  border: none;
  text-transform: capitalize;
  display: inline-block;
  vertical-align: top;
  font-weight: 600;
  color: #283e43;
  cursor: pointer;
  transition: all 200ms linear;
  position: relative;
}

.filter-btn:after {
  content: "";
  display: block;
  top: 4px;
  bottom: 4px;
  position: absolute;
  right: 0px;
  width: 1px;
  background: #283e43;
}

.filter-btn:last-child:after {
  display: none;
}

.single-work {
  margin: 0 0 30px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  transition: all 200ms cubic-bezier(0.32, 0.2, 0.55, 0.6);
  background-color: #fff;
}

.single-work:hover {
  box-shadow: 0 3px 25px 0 rgba(0, 0, 0, 0.1);
}

.single-work:hover img {
  transform: translateY(-30px);
  transition: all 400ms cubic-bezier(0.32, 2.2, 0.55, 1.6);
}

.single-work:hover .work-details {
  transform: translateY(0%);
}

.single-work img {
  border-radius: 5px;
  transition: all 300ms cubic-bezier(0.32, 0.2, 0.55, 1);
}

.work-details {
  width: 100%;
  padding: 10px;
  text-align: center;
  position: absolute;
  background-color: #fff;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateY(100%);
  transition: all 400ms cubic-bezier(0.32, 0.2, 0.55, 1.6);
}

.featured-name {
  display: block;
  color: #425457;
  text-transform: capitalize;
  font-weight: 600;
  transition: all 200ms linear;
}

.resume-content:before,
.resume-content:after,
.resume-content.animated .last-dots {
  background-color: #239eb8;
}
.btn-warning {
  background-color: #e96224;
  color: white;
}
.btn-warning :hover {
  background-color: #e96224;
  color: white;
}
