@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anonymous+Pro:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro);
.rounded-corners {
  border-radius: 50%;
}

.contact-info {
  overflow: hidden;
  list-style: none;
  padding-top: 16px;
}

.contact-info li {
  display: flex;
  border: none;
  line-height: 16px;
}

.contact-info li .info-title {
  font-size: 14px;
  color: #283e43;
  padding-left: 16px;
  font-weight: 600;
  /* float: left; */
  text-transform: capitalize;
  flex: 0 0 100px;
}

.contact-icon {
  font-size: 12px;
}
.contact-info li .info-title span {
  display: inline-block;
  vertical-align: middle;
}

.contact-info li .info-title > span {
  margin-right: 5px;
}

.contact-info li .info-title span {
  display: inline-block;
  vertical-align: middle;
}

.contact-info li .info-title > span {
  margin-right: 5px;
}
.info-detail {
  display: block;
  padding: 0 0 10px;
  flex: 1 1;
  position: relative;
}

.info-detail:after {
  content: ":";
  position: absolute;
  left: -11px;
  top: 0;
  display: block;
  font-weight: bold;
}
.contact-card {
  margin-top: 16px;
}
.margin-top-1 {
  margin-top: 40px;
}
.margin-bottom-1 {
  margin-top: 24px;
}

/*==========================
	07. resume Area
	==========================*/

.resume {
  background-color: #fff;
}

.resume-content {
  padding: 0 0 0 40px;
  position: relative;
  margin: 0 0 30px;
}

.resume-content:before,
.resume-content:after {
  content: "";
  position: absolute;
  display: block;
  left: 0;
  width: 2px;
  top: 16px;
}

.resume-content.animated:before {
  animation: drowLeft 300ms linear forwards;
  width: 30px;
  height: 2px;
  transform-origin: 100% 50%;
}

.resume-content.animated:after {
  width: 2px;
  animation: drowDown 1s cubic-bezier(0.32, 0.2, 0.55, 1) 250ms forwards;
}

.resume-content.animated .last-dots {
  position: absolute;
  left: -2px;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  bottom: 0;
  display: block;
  transform: scale(0);
  animation: dotsBlock 400ms cubic-bezier(0.32, 2.2, 0.55, 1.6) 1.2s forwards;
}

@keyframes drowLeft {
  from {
    transform: scaleX(0);
  }

  to {
    transform: scaleX(1);
  }
}

@keyframes drowDown {
  from {
    bottom: 100%;
  }

  to {
    bottom: 0%;
  }
}

@keyframes dotsBlock {
  from {
    transform: scale(0);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

.resume-content h3 {
  font-weight: 500;
  margin: 0 0 20px;
}

.single-resume {
  overflow: hidden;
  padding: 20px 0 10px;
  border-bottom: 1px solid #eee;
}

.single-resume:nth-child(3) {
  border-top: 1px solid #eee;
}

.single-resume:last-child {
  padding: 20px 0;
}

.single-resume:last-child,
.single-resume:last-child p {
  margin: 0;
}

.resume-title {
  display: block;
  font-weight: bold;
  font-size: 14px;
  margin: 0 0 3px;
}

.date {
  display: block;
  font-size: 11px;
  margin: 0 0 3px;
  color: #888;
  font-weight: 500;
}

.resume-content .discribe {
  font-size: 14px;
  line-height: 20px;
}

/*==========================
	08. featured work area
	==========================*/

.featured-work {
  background-color: #fafafa;
}

.portfolio-filter {
  display: block;
}

.filter-btn {
  background: transparent;
  padding: 0 12px;
  border: none;
  text-transform: capitalize;
  display: inline-block;
  vertical-align: top;
  font-weight: 600;
  color: #283e43;
  cursor: pointer;
  transition: all 200ms linear;
  position: relative;
}

.filter-btn:after {
  content: "";
  display: block;
  top: 4px;
  bottom: 4px;
  position: absolute;
  right: 0px;
  width: 1px;
  background: #283e43;
}

.filter-btn:last-child:after {
  display: none;
}

.single-work {
  margin: 0 0 30px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  transition: all 200ms cubic-bezier(0.32, 0.2, 0.55, 0.6);
  background-color: #fff;
}

.single-work:hover {
  box-shadow: 0 3px 25px 0 rgba(0, 0, 0, 0.1);
}

.single-work:hover img {
  transform: translateY(-30px);
  transition: all 400ms cubic-bezier(0.32, 2.2, 0.55, 1.6);
}

.single-work:hover .work-details {
  transform: translateY(0%);
}

.single-work img {
  border-radius: 5px;
  transition: all 300ms cubic-bezier(0.32, 0.2, 0.55, 1);
}

.work-details {
  width: 100%;
  padding: 10px;
  text-align: center;
  position: absolute;
  background-color: #fff;
  bottom: 0;
  left: 0;
  right: 0;
  transform: translateY(100%);
  transition: all 400ms cubic-bezier(0.32, 0.2, 0.55, 1.6);
}

.featured-name {
  display: block;
  color: #425457;
  text-transform: capitalize;
  font-weight: 600;
  transition: all 200ms linear;
}

.resume-content:before,
.resume-content:after,
.resume-content.animated .last-dots {
  background-color: #239eb8;
}
.btn-warning {
  background-color: #e96224;
  color: white;
}
.btn-warning :hover {
  background-color: #e96224;
  color: white;
}

:root {
  --primary-color: #e96224;
  --hover-color: #ff6600;
}

/* Add your profile image here */
.Photo {
  background-image: url(/static/media/aboutme.c72b2456.jpg);
}
/* Projects Photo */
.project1 {
  background-image: url("https://via.placeholder.com/400x600.png?text=Project+one");
  cursor: none;
}
.project2 {
  background-image: url("https://via.placeholder.com/400x600.png?text=Project+two");
  cursor: none;
}
.project3 {
  background-image: url("https://via.placeholder.com/400x600.png?text=Project+three");
  cursor: none;
}
.project4 {
  background-image: url("https://via.placeholder.com/400x600.png?text=Project+four");
  cursor: none;
}
.project5 {
  background-image: url("https://via.placeholder.com/400x600.png?text=Project+five");
  cursor: none;
}
.project6 {
  background-image: url("https://via.placeholder.com/400x600.png?text=Project+six");
  cursor: none;
}
/* Miscelaneous Activities photo */
.misc1 {
  background-image: url("https://via.placeholder.com/400x600.png?text=Miscellaneous+one");
  cursor: none;
}
.misc2 {
  background-image: url("https://via.placeholder.com/400x600.png?text=Miscellaneous+two");
  cursor: none;
}
.misc3 {
  background-image: url("https://via.placeholder.com/400x600.png?text=Miscellaneous+three");
  cursor: none;
}

* {
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  background-color: #fff;
}
html.dark-mode {
  background-color: #000;
}
button:focus {
  outline: 1px solid #777 !important;
}
.lead {
  font-weight: 400;
}
.bg-danger {
  background-color: var(--primary-color) !important;
}
.Header {
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-family: Montserrat, sans-serif;
}
.Header h1 {
  color: #000;
  text-align: center;
  font-size: 5.5rem;
  position: absolute;
}
.Header p {
  margin-top: 125px;
}
.particle {
  position: absolute!;
  margin: 0 !important;
  margin-top: -100vh !important;
}
.underline {
  text-decoration: underline;
  -webkit-text-decoration-color: var(--primary-color);
          text-decoration-color: var(--primary-color);
}
.skills {
  padding: 3%;
}
.progress-block {
  padding: 10px 0;
}
.progress-block h2 {
  font-size: x-large;
}
.dark-mode {
  filter: invert(1) hue-rotate(180deg);
  -webkit-filter: invert(1) hue-rotate(180deg);
  -moz-filter: invert(1) hue-rotate(180deg);
}
.inverse-dark {
  filter: invert(1) hue-rotate(180deg);
  -webkit-filter: invert(1) hue-rotate(180deg);
  -moz-filter: invert(1) hue-rotate(180deg);
}
.effect2 {
  position: relative;
  width: 70%;
  background: #fff;
  padding: 5%;
  margin: 10% auto;
  border-bottom: solid 1px #777 !important;
}
.effect2:after,
.effect2:before {
  z-index: -1;
  position: absolute;
  content: "";
  bottom: 15px;
  left: 10px;
  width: 50%;
  top: 80%;
  max-width: 300px;
  background: #777;
  box-shadow: 0 15px 10px #777;
  transform: rotate(-3deg);
}
.effect2:after {
  transform: rotate(3deg);
  right: 10px;
  left: auto;
}
.Photo {
  background-color: #fff;
  background-size: contain;
  background-repeat: no-repeat;
}
.Photo.inverse-dark {
  background-color: #000;
}
.about-text {
  width: 80%;
  padding: 2%;
}
.red-line {
  color: var(--primary-color);
  font-weight: 500;
}
.About-title-box {
  box-sizing: border-box;
  width: 50%;
  padding-bottom: 5px;
  border-bottom: solid 2px var(--primary-color);
  margin-bottom: 15px;
}
.skill__square {
  display: inline;
  margin: 15px 0 !important;
}
.skill__square i {
  font-size: 50px !important;
  color: #777 !important;
  height: 20px !important;
}
.skill__square svg {
  width: 35px !important;
  min-height: 20px !important;
  padding: 0;
  margin: 0;
  color: #777 !important;
}
.skill__square h6 {
  text-align: center;
  margin: auto;
  font-size: 1em;
  padding-top: 5px;
}
.skill__square i:hover,
.skill__square svg:hover {
  color: #ff4753 !important;
}
.third {
  width: 75%;
  margin: 10% auto;
  align-items: center;
  padding: 3%;
  justify-content: center;
}
.project-card {
  padding: 15px;
  margin-top: 15px;
  box-shadow: rgba(17, 12, 46, 0.15) 0 48px 100px 0;
  border: 0.5px solid #eee;
}
.project-card h2 {
  color: var(--primary-color);
  margin-top: 10px;
  margin-left: 3%;
  font-weight: 500;
}
.project-card h2:hover {
  color: var(--hover-color);
  text-decoration: none;
}
.project-card h3 {
  text-align: left;
  font-size: 1rem;
  margin-left: 3%;
}
.image-project {
  height: 300px;
  width: auto;
  margin-bottom: 20px;
  overflow: hidden;
  cursor: pointer !important;
}
.img-pro {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 0.5s;
}
.image-project:hover .img-pro {
  transform: scale(1.2);
}
.pp-head {
  text-align: center;
}
.pp-head-line {
  box-sizing: border-box;
  width: 30%;
  padding-bottom: 5px;
  border-bottom: solid 2px var(--primary-color);
  margin-bottom: 15px;
}
.pp-text {
  font-size: 1.5rem;
}
.parallax {
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.git-form {
  width: 1160px;
  box-sizing: border-box;
  display: block;
  margin: 3rem 3rem 0 3rem;
  background-color: #fff;
  max-height: 80vh;
}
.git-head-div {
  box-sizing: border-box;
  padding-top: 5%;
  padding-bottom: 15px;
  width: 30%;
  border-bottom: 3px solid #000;
  color: #000;
  font-weight: 500;
  text-align: center;
}
.git-cont {
  display: inline-block;
}
.half {
  display: inline-block;
  vertical-align: top;
}
.half form input,
textarea {
  width: 100%;
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
  outline: 0;
  box-shadow: none;
  border: 1px solid #777;
}
.half form textarea {
  padding-bottom: 45px;
}
.half form input:focus,
textarea:focus {
  border-radius: 5px;
  outline: 0;
  box-shadow: none;
  border: 1px solid var(--primary-color);
}
.half form button {
  padding: 10px 15px;
  font-size: 1rem;
  border-radius: 2rem;
  color: #fff;
  background-color: var(--primary-color);
  box-shadow: none;
  outline: 0;
  border: none;
  margin-top: 20px;
  margin-left: 20px;
}
.half form button:hover {
  background-color: var(--hover-color);
}
.half form {
  padding: 3rem 1rem;
}
.half p {
  padding: 3rem 1rem 0 1rem;
  text-align: center;
}
.inline-button {
  display: inline;
  vertical-align: middle;
  display: flex;
  flex-direction: row;
}
.git-cont .fab {
  vertical-align: bottom;
  padding: 10px;
  font-size: 30px;
  height: 30px;
  text-align: center;
  text-decoration: none;
  color: var(--primary-color);
  transition: 0.5;
}
.git-cont .fab:hover {
  color: var(--hover-color);
}
.git-cont .fas {
  vertical-align: bottom;
  font-size: 40px;
  height: 30px;
  margin: 25px;
  text-align: center;
  text-decoration: none;
  color: #000;
  transition: 0.5;
}
.navbar {
  width: 100%;
  position: fixed;
  z-index: 1001;
  top: 0;
  box-shadow: 0 8px 6px -6px #646464;
}
.nav-link {
  color: #000 !important;
}
.is-current {
  color: var(--primary-color);
  text-decoration: underline;
  -webkit-text-decoration-style: solid 3px;
          text-decoration-style: solid 3px;
  -webkit-text-decoration-color: var(--primary-color);
          text-decoration-color: var(--primary-color);
}
.Copy {
  color: #fff !important;
  font-weight: 500;
  padding-top: 2%;
}
.project-name {
  text-decoration: none;
}
.gtp {
  position: fixed;
  left: 92%;
  height: 150px;
  top: 82%;
  z-index: 1002;
  cursor: crosshair;
}
.see {
  background-color: var(--primary-color);
  color: #fff;
  border: #000;
  outline: #000;
  position: absolute;
  padding: 5px 15px;
  padding-bottom: 7px;
  border-radius: 3px;
  left: 44%;
  top: 90%;
}
.fa-eye {
  color: #fff;
  font-size: 20px;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 15px;
  padding-right: 7px;
}
.see:active {
  outline: 0;
}
.see:hover {
  background-color: var(--hover-color);
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  left: 44.5%;
  bottom: 46%;
}
.Header p {
  margin-top: 40px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--primary-color);
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: #fff;
  transition: 0.4s;
}
input:checked + .slider {
  background-color: #000;
}
input:focus + .slider {
  box-shadow: 0 0 1px #000;
}
input:checked + .slider:before {
  transform: translateX(26px);
}
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}
.line-1 {
  position: relative;
  top: 10%;
  width: 12em;
  margin: 0 auto;
  border-right: 2px solid rgba(25, 25, 25, 0.75);
  font-size: 180%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  transform: translateY(-50%);
  font-family: "Anonymous Pro", monospace !important;
}
.anim-typewriter {
  animation: typewriter 4s steps(27) 1s 1 normal both,
    blinkTextCursor 0.5s steps(27) infinite normal;
}
.parallax {
  background-image: url(https://images.pexels.com/photos/691668/pexels-photo-691668.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260);
}
@keyframes typewriter {
  from {
    width: 0;
  }
  to {
    width: 15em;
  }
}
@keyframes blinkTextCursor {
  from {
    border-right-color: rgba(25, 25, 25, 0.75);
  }
  to {
    border-right-color: transparent;
  }
}
::selection {
  color: #fff;
  background: var(--primary-color);
}
@media (max-width: 1300px) {
  .effect2 {
    width: 100%;
  }
}
@media (max-width: 800px) {
  .lead {
    font-weight: 300;
  }
  .Header h1 {
    font-size: 2.5rem;
  }
  .line-1 {
    font-size: 80%;
    top: 5.5%;
  }
  .underline {
    -webkit-text-decoration-color: (0, 0, 0, 0.1);
            text-decoration-color: (0, 0, 0, 0.1);
  }
  .about-text {
    width: 100%;
    text-align: center;
  }
  .red-line::before {
    bottom: 590px;
  }
  .effect2 {
    width: 100%;
  }
  .skills {
    padding: 10% 5%;
  }
  .third {
    width: 100%;
  }
  .project-card {
    width: 100% !important;
  }
  .image-project {
    width: 100% !important;
  }
  .third {
    padding: 5%;
  }
  .pp-head-line {
    width: 70%;
  }
  .git-form {
    width: 90%;
    padding: 5px;
    padding-bottom: 35px;
  }
  .git-head-div {
    width: 75%;
  }
  .switch {
    bottom: 48%;
    left: 35%;
  }
  .half form input,
  textarea {
    margin: 5px 0;
    padding: 10px;
  }
  .half p {
    display: none;
  }
  .gtp {
    left: 80%;
    top: 87%;
    height: 75px;
  }
  .Copy {
    display: none;
  }
  .see {
    margin: 0;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1rem;
  }
  .fa-eye {
    font-size: 20px;
  }
  .half form button {
    margin-left: auto;
    margin-right: auto;
  }
}

